import axios from "@/common/interceptors.service";
import ApiService from "@/common/api.service";

export default {
  assignYearlyPro(id) {
    return ApiService.post(`admin/users/${id}/assign-pro`);
  },
  assignRemovePro(id) {
    return ApiService.post(`admin/users/${id}/remove-pro`);
  },
  assignTeacher(id) {
    return ApiService.post(`admin/users/${id}/assign-teacher`);
  },
  getQuestionsByAI(data) {
    return ApiService.post("bot/chat", data);
  },
  getUserTagAnalysis(id) {
    return ApiService.query(`/users/${id}/tag-analysis`);
  },
  getResultsByExam(data) {
    return ApiService.query("/results", data);
  },
  getAllConversions(data) {
    return ApiService.query("/sat-editor/sat-score-categories", data);
  },
  createConversion(data) {
    return ApiService.post("sat-editor/sat-score-categories", data);
  },
  //查看評分表
  getConversions(id) {
    return ApiService.query(`sat-editor/sat-score-categories/${id}`);
  },
  updateConversion(id, data) {
    return ApiService.post(`sat-editor/sat-score-categories/${id}/sync`, data);
  },
  //10 20 修改 =================================================================
  getEditorComposePractice(){
    return ApiService.query("editor/compose-practices");
  },
  createComposePractice(data) {
    return ApiService.post("editor/compose-practices", data);
  },
  updateComposePractice(id, data) {
    // return ApiService.update("compose-practice-editor", id, data);
    return ApiService.patch(`editor/compose-practices/${id}`, data);
  },
  deleteComposePractice(id) {
    return ApiService.delete(`editor/compose-practices/${id}`);
  },
  updateComposePracticePractices(id, data) {
    return ApiService.patch(`editor/compose-practices/${id}/practices`, data);
  },
  //10 20 修改 =================================================================
  addPracticeQuestion(id, data) {
    return ApiService.post(`practice-question-editor/${id}/questions`, data);
  },
  createAdaptive(data) {
    return ApiService.post("editor/adaptive-practices", data);
  },
  updateAdaptive(id, data) {
    return ApiService.post(`editor/adaptive-practices/${id}`, data);
  },
  getAdaptiveEdit(id) {
    return ApiService.query(`editor/adaptive-practices/${id}`);
  },
  getAdaptivePractices(data) {
    return ApiService.query("exam/adaptive-practices", data);
  },
  getAdaptive(id) {
    return ApiService.query(`exam/adaptive-practices/${id}`);
  },
  removePracticeQuestion(id, data) {
    return ApiService.delete(`practice-question-editor/${id}/questions`, data);
  },
  // 10.20 修改 =================================================================
  getEditorPractices(){
    return ApiService.query("editor/practices");
  },
  // Admin
  createPractice(data) {
    return ApiService.post("editor/practices", data);
  },
  updatePracticeQuestions(id, data) {
    return ApiService.patch(`editor/practices/${id}/questions`, data);
  },
  updatePracticeQuestionsOrder(id, data) {
    return ApiService.patch(
      `practice-question-editor/${id}/question-order`,
      data
    );
  },
  updatePractice(id, data) {
    // return ApiService.update("practice-editor", id, data);
    return ApiService.update("editor/practices", id, data);
  },
  updateStatus(id, data) {
    // return ApiService.update("practice-editor", id, data);
    return ApiService.patch(`exams/${id}/status`, data);
  },
  deletePractice(id) {
    return ApiService.delete("editor/practices", id);
  },
  // 10.20 修改 =================================================================
  getSubscriptions() {
    return ApiService.query("subscriptions");
  },
  showSubscriptions(id) {
    return ApiService.query(`subscriptions/${id}`);
  },
  Unsubscribe(id) {
    return ApiService.post(`subscriptions/${id}/unsubscribe`);
  },
  getSats() {
    // return axios.get("http://localhost:3000/sats");
  },
  getStaticSatTests() {
    return axios.get("http://localhost:3000/examins");
  },
  getSatBooks() {
    return axios.get("http://localhost:3000/books");
  },
  //获取所有考卷
  getPractices(data) {
    return ApiService.query("practice", data);
  },
  getClassPractices(id, data) {
    return ApiService.query("session-classes/1/exams", data);
  },
  getComposePractices(data) {
    return ApiService.query("compose-practice", data);
  },
  getAllComposePractices(data) {
    return ApiService.query("compose-practice/user-taken", data);
  },
  //获取考卷内容
  getPracticeTest(id) {
    return ApiService.query(`practice/${id}`);
  },
  getComposePracticeTest(id) {
    return ApiService.query(`compose-practice/${id}`);
  },
  //生成成绩单
  TakePracticeUserExam(id) {
    return ApiService.post(`practice-exam/take-exam/${id}`);
  },
  TakeComposePracticeUserExam(id) {
    return ApiService.post(`compose-practice-exam/take-exam/${id}`);
  },
  unlockResult(id) {
    return ApiService.post(`user-exams/${id}/trial-status`);
  },
  //提交答案
  updatePracticeAnswers(id, data) {
    return ApiService.post(`practice-exam/answer/${id}`, data);
  },
  updateComposePracticeAnswers(id, data) {
    return ApiService.post(`compose-practice-exam/answer/${id}`, data);
  },

  //获取成绩单列表
  getPracticeUserExam(data) {
    return ApiService.query("user-exam/practice", data);
  },
  getAdminPracticeUserExam(data) {
    return ApiService.query("admin/user-exam/practice", data);
  },
  getTeacherUserExam(data) {
    return ApiService.query("teacher/user-exams", data);
  },
  getCompanyPracticeUserExam(data) {
    return ApiService.query("company/user-exams", data);
  },
  getComposePracticeUserExam(data) {
    return ApiService.query("user-exam/compose-practice", data);
  },
  getAdminComposePracticeUserExam(data) {
    return ApiService.query("admin/user-exam/compose-practice", data);
  },
  getSubjects() {
    return ApiService.query("sat-editor/subjects");
  },
  addQuestion(data) {
    return ApiService.post("sat-editor/create", data);
  },
  getSATS(data) {
    return ApiService.query("sat-editor/sats", data);
  },
  addNewSAT(data) {
    return ApiService.post("/sat-editor/sats", data);
  },
  addNewQuestion(data) {
    return ApiService.post("sat-editor/questions", data);
  },
  updateNewQuestion(id, data) {
    return ApiService.update("sat-editor/questions", id, data);
  },
  addPassage(data) {
    return ApiService.post("sat-editor/passages", data);
  },
  searchAllPassages(data) {
    return ApiService.query("sat-editor/passages", data);
  },
  getPassage(id) {
    return ApiService.query(`sat-editor/passages/${id}`);
  },
  updatePassage(id, data) {
    return ApiService.update("sat-editor/passages", id, data);
  },
  //判断这份考卷是否做过
  hasTakenPractice(examId) {
    return ApiService.query(`/practice-exam/has-taken-exam/${examId}`);
  },
  hasTakenComposePractice(examId) {
    return ApiService.query(`/compose-practice-exam/has-taken-exam/${examId}`);
  },
  //获取做过的考卷
  getPastComposePractice(user_exam_id) {
    return ApiService.post(
      `compose-practice-exam/take-past-exam/${user_exam_id}`
    );
  },
  getPastPractice(user_exam_id) {
    return ApiService.post(`practice-exam/take-past-exam/${user_exam_id}`);
  },
  //获取一份成绩单
  getPracticeResult(id) {
    return ApiService.query(`practice-exam/result/${id}`);
  },
  getComposePracticeResult(id) {
    return ApiService.query(`compose-practice-exam/result/${id}`);
  },
  //查看解析
  getExplanation(id, data) {
    return ApiService.post(`/sat/explanation/${id}`, data);
  },
  getSatQuestions(data) {
    return ApiService.query("sat-editor/sats", data);
  },
  //查看考卷的subjects
  getExamSubjects(id) {
    return ApiService.query(`/sat-editor/sats/${id}/subjects`);
  },
  getSatSubjectQuestions(id, data) {
    return ApiService.query(`sat-editor/sat-subjects/${id}`, data);
  },
  getSATQuestionTags() {
    return ApiService.query("sat/question-tags");
  },
  createSATQuestionTags(data) {
    return ApiService.post("sat-question-tags", data);
  },
  updateSATQuestionTags(id, data) {
    return ApiService.patch(`sat-question-tags/${id}`, data);
  },
  deleteSATQuestionTags(id) {
    return ApiService.delete(`sat-question-tags/${id}`);
  },
  getSatPracticeQuestions(data) {
    return ApiService.query("practice-question-selector", data);
  },
  getQuestionDetail(id) {
    return ApiService.query(`sat-editor/questions/${id}`);
  },
  getPractice(data) {
    return ApiService.query("practice", data);
  },
  getPracticeDetail(id) {
    return ApiService.query(`practice/${id}`);
  },
  getEditorPracticeDetail(id) {
    return ApiService.query(`editor/practices/${id}`);
  },
  getComposePracticeDetail(id) {
    return ApiService.query(`/compose-practice/${id}/`);
  },
  getEditorComposePracticeDetail(id) {
    return ApiService.query(`editor/compose-practices/${id}/`);
  },
  // getAdaptiveComposePracticeDetail(id) {
  //   return ApiService.query(`editor/adaptive-practices/${id}/`);
  // },
  //toefl
  getToefls(data) {
    return ApiService.query("toefls", data);
  },
  getToeflTags() {
    return ApiService.query("toefls/tags");
  },
  getExamId(data) {
    return ApiService.post("toefls/get-exam-id", data);
  },
  getQuestions(exam_id, data) {
    return ApiService.get("toefls/questions", exam_id, data);
  },
  /*
  getAnswers(data) {
    return ApiService.get("toefls/answers", data);
  },
  */
  updateAnswers(id, data) {
    return ApiService.update("toefls/answer", id, data);
  },
  uploadAudio(data) {
    return ApiService.post("document", data);
  },
  deleteAudio(id) {
    return ApiService.delete(`document/${id}`);
  },
  async getTranscript(answerId) {
    const transcript = await axios.get("http://localhost:3000/transcript");

    if (answerId) {
      return {
        user_exam_answer: {
          id: 685690,
          user_exam_id: 5262,
          exam_question_id: 30021,
          answers: ["B"],
          is_correct: 0,
          score: 0,
          created_at: "2020-11-10T06:43:35.000000Z",
          updated_at: "2020-11-10T06:43:36.000000Z"
        }
      };
    }

    return transcript;
  },
  async getResolve(user_exam_id, data) {
    return axios.get("http://localhost:3000/resolve");
  },
  getBrowse(toefl_chapter_id) {
    return ApiService.get("/toefl/chapters", toefl_chapter_id);
  },
  getAnswers(exam_id) {
    return axios.get("http://localhost:3000/answers");
  },
  getType(exam_id) {
    return axios.get("http://localhost:3000/questions");
  },
  getTest(exam_id) {
    return axios.get("http://localhost:3000/sat-editor");
  },
  getSubjectPassages(subjectId) {
    return axios.get("http://localhost:3000/passages");
  },
  // getPassage(passageId) {
  //   return axios.get("http://localhost:3000/passage");
  // },
  async getSubjectQuestions() {
    const { sat_questions } = await axios.get(
      "http://localhost:3000/subjectQuestions"
    );
    return sat_questions;
  },
  getQuestion() {
    return axios.get("http://localhost:3000/question");
  },
  getScoreConversion(exam_id) {
    return axios.get("http://localhost:3000/score-conversion");
  },
  getSubscoreConversion(exam_id) {
    return axios.get("http://localhost:3000/subscore-conversion");
  },
  getCrossTestConversion(exam_id) {
    return axios.get("http://localhost:3000/cross-test-conversion");
  },
  getAllTranscripts(data) {
    return axios.get("http://localhost:3000/allTranscripts");
  },
  gradeScore(user_exam_answer_id, data) {
    return ApiService.update("toefls/grade", user_exam_answer_id, data);
  },
  updateScore(id) {
    return ApiService.update("toefls/update-score", id);
  },
  openPackages() {
    return ApiService.query("packages");
  },
  createOrder(data) {
    return ApiService.post("order", data);
  },
  getTransactions() {
    return ApiService.query("point-transactions");
  },
  getTransactionsForcorrect(data) {
    return ApiService.query("/toefls/transcripts-for-correct", data);
  },
  getPointPackage(id) {
    return ApiService.query("/point-package/" + id);
  },
  payForPackage(id, data) {
    return ApiService.post("/exam/" + id + "/pay-for-point-package", data);
  },
  updateTranscript(id, data) {
    return ApiService.update("/toefls/grade", id, data);
  },
  getNotification() {
    return ApiService.query("/toefls/not-score-notification/");
  },
  getTests(data) {
    return ApiService.query("compose-practice-combine/user-exams", data);
  },
  getComposePracticePractices(id) {
    return ApiService.query(`compose-practice/${id}/practices`);
  },
  combineTests(id, data) {
    return ApiService.post(`compose-practice-combine/${id}/combine`, data);
  },
  sendEmail(userExam) {
    return ApiService.post(`/toefls/corrected/${userExam}`);
  },
  getStudents(data) {
    return ApiService.query("admin/users", data);
  },
  giveCoins(uerId,data){
    return ApiService.post(`admin/users/${uerId}/give-coins`, data);
  },
  deductCoins(uerId, data){
    return ApiService.post(`admin/users/${uerId}/deduct-coins`, data);
  },
  resetCoins(uerId) {
    return ApiService.post(`admin/users/${uerId}/reset-coins`);
  },
  getWriting() {
    return ApiService.query("dev/toefl-testing");
  },
  getContent(id) {
    return ApiService.query("dev/toefl-all-except-writing?tpo=" + id);
  },
  postFeedback(id, data) {
    return ApiService.post(`exam/${id}/refund-point`, data);
  },
  payForPDF(id) {
    return ApiService.post(`exam/${id}/pay-for-print-pdf`);
  },
  payByUnlockNumberForPDF(id) {
    return ApiService.post(`exam/${id}/exchange-print-pdf`);
  },
  viewPdf(id) {
    return ApiService.query(`toefls/transcript/${id}/print`);
  },
  getExamPrintPdfIsUnlock(id) {
    return ApiService.get(`exam/${id}/unlock-print-pdf-status`);
  },
  checkFiles(id) {
    return ApiService.get(`toefls/audio-urls/${id}`);
  },
  getQuestionCount() {
    return ApiService.get("toefl-question-count");
  },
  copyPractice(id) {
    return ApiService.post(`practice/${id}/copy`);
  },
  copyComposePractice(id) {
    return ApiService.post(`compose-practice/${id}/copy`);
  },
  reGrading(examId) {
    return ApiService.post(`/exams/${examId}/regrading`);
  },
  reGradingUserExam(userExamId) {
    return ApiService.post(`/admin/user-exams/${userExamId}/regrading`);
  },
  setExamFinished(userExamId){
    return ApiService.post(`user-exams/${userExamId}/finish`);
  },
  setExamSection(userExamId,data) {
    return ApiService.patch(`user-exams/${userExamId}/past-order`, data);
  },
  removeExam(examId) {
    return ApiService.delete(`/admin/exams/${examId}`);
  },
  removeTestResult(role, id) {
    ApiService.delete(`${role}/user-exams/${id}`);
  },
  getIvywayClasses(examId) {
    return ApiService.query(`ivy-way/exams/${examId}/session-class-assign-tests`);
  },
  startTestForIvyway(id, data) {
    return ApiService.post(`ivy-way/exams/${id}/obtain`, data);
  }
};
