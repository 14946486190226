<template>
  <div
    :class="
      showQuestion
        ? 'row question-detail show-FullQuestion'
        : 'row question-detail'
    "
    ref="question-detail"
  >
    <!-- <table class="table table-bordered">
      <tbody>
        <tr>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table> -->
    <div
      v-if="showPassage && question.passage && question.passage.content !== ''"
      class="col-6 p-0"
      style="border-right: 2px solid #212529;"
    >
      <div
        class="question-detail-passage question-content-title"
        v-html="changeColor(question.passage.content)"
      ></div>
    </div>
    <div
      :class="
        showPassage && question.passage && question.passage.content !== ''
          ? 'col-6 p-0'
          : 'col-12 p-0'
      "
    >
      <div class="question-detail-question">
        <div class="question-detail-question-order">
          <kbd v-if="order">
            {{ order }}
          </kbd>
          <router-link
            class="edit-button"
            v-if="isAdmin"
            :to="{
              name: 'SatEditQuestion',
              query: {
                questionId: question.id
              }
            }"
          >
            <i class="fa fa-edit"></i>
          </router-link>
        </div>
        <div
          class="question-detail-question-content question-content-title"
          v-if="showQuestion || showOptions || showAnswer"
        >
          <div v-if="showQuestion">
            <b v-html="changeColor(question.content)"></b>
          </div>
          <div>
            <ul
              class="ivy-question-options"
              v-if="question.type === 'single' && showOptions"
            >
              <li
                class="option"
                v-for="(option, index) in question.options"
                :key="index"
              >
                <div
                  v-if="question.answers"
                  v-show="showAnswer"
                  style="width:1.5rem"
                >
                  <span class="text-success">
                    <i
                      v-if="isAnswer(question.answers, option.label)"
                      class="fas fa-check-circle"
                    ></i>
                  </span>
                </div>
                <span style="margin-right: 4px">{{ `${option.label}) ` }}</span>
                <div
                  class="optionText"
                  v-html="changeColor(option.content)"
                ></div>
              </li>
            </ul>
            <template>
              <div class="mt-2" v-show="showAnswer">
                <b>Answer:</b>
                <span
                  class="ml-1"
                  v-for="(answer, index) in question.answers"
                  :key="index"
                >
                  {{ answer.answer[0] }}
                  <span v-if="index < question.answers.length - 1">
                    ,
                  </span>
                </span>
              </div>
            </template>
          </div>
          <template v-if="questionTags(question.question_tags)">
            <div v-show="showLabels">
              <div
                v-for="(tag_analysis, key, index) in questionTags(
                  question.question_tags
                )"
                :key="index"
                class="pb-2"
              >
                <b>{{ key }}</b
                >:
                <el-tag
                  v-for="tag in tag_analysis"
                  :key="tag.id"
                  type="success"
                  size="small"
                  class="ivy-tag"
                >
                  <b>{{ tag.name }}</b>
                </el-tag>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      v-if="
        showExplanation &&
          question.explanations &&
          question.explanations.length > 0
      "
      class="col-12 p-0"
      style="border-top: 2px solid #212529;"
    >
      <div class="question-detail-explanation">
        <b class="mb-1">Explanation:</b>

        <div v-for="explanation in question.explanations" :key="explanation.id">
          <div class="video-box" v-if="isValidUrl(explanation.explanation)">
            <iframe :src="setUrl(explanation.explanation)"></iframe>
          </div>
          <div v-else v-html="explanation.explanation"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";

export default {
  components: {},
  mixins: [Common],
  props: [
    "isAdmin",
    "question",
    "order",
    "search",
    "showPassage",
    "showQuestion",
    "showOptions",
    "showAnswer",
    "showExplanation",
    "showLabels"
  ],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    isValidUrl(string) {
      var v = new RegExp(
        "^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-?)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$",
        "i"
      );
      return v.test(this.setUrl(string));
    },
    setUrl(string) {
      string = string.replace(/<[^>]+>/g, "");
      string = string.replace("watch?v=", "embed/");
      return string;
    },
    questionTags(tags) {
      let questionTags = null;
      if (tags) {
        questionTags = _.groupBy(tags, "type");
      }
      return questionTags;
    },
    isAnswer(answers, answer) {
      let isAnswer = false;
      answers.forEach(e => {
        if (e.answer && e.answer.length > 0) {
          e.answer.forEach(item => {
            if (item.indexOf(answer) > -1) {
              isAnswer = true;
            }
          });
        }
      });
      return isAnswer;
    },
    changeColor(result) {
      if (this.search) {
        let replaceReg = new RegExp(this.search, "ig");
        let replaceString = `<span style="background-color: var(--themeColor);color:white;padding:0 0.1rem">${this.search}</span>`;
        result = result.replace(replaceReg, replaceString);
      }
      return result;
    }
  }
};
</script>

<style scoped>
.video-box {
  max-width: 100%;
  max-height: 300px;
  aspect-ratio: 21 / 9;
}
.video-box iframe {
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.question-detail {
  font-family: DroidSerif;
  border: 2px solid #212529;
  margin: 0;
  font-size: 16px;
}
.edit-button {
  display: none;
}
.question-detail:hover .edit-button {
  display: block;
}
.question-detail-passage {
  padding: 0.5rem;
}
.question-detail-question {
}
.question-detail-question-order {
  padding: 0.5rem;
  border-bottom: 2px solid #212529;
  background-color: #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.question-detail-question-content {
  padding: 0.5rem;
}
.question-detail-explanation {
  padding: 0.5rem;
}
/* ::v-deep .question-content-title img
{
  display: none;
} */
::v-deep .question-content-title img {
  display: block;
  max-width: 400px;
}
::v-deep .el-popper {
  width: 450px !important;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.ivy-question {
  font-family: DroidSerif;
}
::v-deep .el-button {
  margin: 0 0.3rem;
}
.passage-title {
  position: absolute;
  border: 1px solid #fefefe;
  background: var(--themeColor);
  /* font-size: 16px; */
  color: white;
  left: -2px;
  top: -15px;
  height: 34px;
  padding: 0 17px;
  line-height: 32px;
  border-radius: 5px 5px 0 0;
}
.question-content {
  position: absolute;
  right: -5px;
  top: -25px;
}
::v-deep .question-content-title p {
  margin: 0;
}
</style>
<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #212529;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.answer_content {
  justify-content: space-around;
}
.cover {
  height: calc(100vh - 200px);
}
.question-order {
  margin: 20px 0;
  background-color: #212529;
}
.ivy-question {
  position: relative;
  overflow: visible;
  background-color: #f8f8f8;
}
.add-button {
  position: absolute;
  top: 50%;
}
.ivy-question .el-card {
  overflow: visible;
}
.ivy-question-options {
  /* font-size: 16px; */
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  /* font-size: 16px; */
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.ivy-tag {
  margin: 5px;
}
.hidden {
  display: none;
}
::v-deep .el-card__body {
  padding: 15px;
}
::v-deep .MathJax .mrow {
  /* font-size: 1rem !important; */
}
::v-deep .highlight {
  background-color: var(--themeColor);
  color: white;
  padding: 0 0.1rem;
}
.is-warning {
  background-color: #c0c4cc;
}
</style>
